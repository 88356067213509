import React from 'react'
import 'assets/styles/fonts/open-sans.css'
import 'assets/styles/fonts/circe.css'
import 'assets/styles/style.scss'
import Helmet from 'react-helmet'

const Iframe = ({ children }) => {
	return (
		<div style={{ padding: '1px' }}>
			<Helmet>
				<script src="/partner/iframe-resizer.child.js" />
			</Helmet>
			{children}
		</div>
	)
}

export default Iframe
