import React, { useEffect, useState } from 'react'
import { default as Layout } from 'layouts/Iframe'
import LocalStorage from 'utils/LocalStorage'
import Catalog from './components/Catalog'
import Content from 'components/Content'

const storage = new LocalStorage('partner')

const DatabasesPartner = props => {
	// Получение и сохранение в localStorage ID партнера
	const [partnerId, setPartnerId] = useState(storage.get('id'))
	useEffect(() => {
		window.iFrameResizer = {
			onMessage: ({ partnerId }) => {
				if (partnerId) {
					setPartnerId(partnerId)
					storage.set('id', partnerId)
				}
			},
		}
	}, [])

	if (!partnerId) return <Layout />

	return (
		<Layout>
			<Content>
				<Catalog {...props} partnerId={partnerId} />
			</Content>
		</Layout>
	)
}

export default DatabasesPartner
